require('./bootstrap');

import '@fortawesome/fontawesome-pro/css/all.css';
import '@fortawesome/fontawesome-pro/js/all.js';

var mainNavFixed = false;
var fullScreenPanel = false;

$(window).on('scroll', function () {
    setMainNav();
});
$(window).on('resize', function () {
    setMainNav();
});

function setMainNav() {
    let headerBarHeight = $('.header-bar').outerHeight();
    if ($('.maintenance-notice').is(":visible")) {
        headerBarHeight += $('.maintenance-notice').outerHeight() || 0;
    }
    const mainNavHeight = $('.main-nav-container').outerHeight();
    const pageScrollTop = $('html, body').scrollTop();
    const windowWidth = $(window).width();

    if (fullScreenPanel || (pageScrollTop > headerBarHeight)) {
        if (!mainNavFixed) {
            mainNavFixed = true;
            $('.main-nav-container').addClass('fixed');
            $('.navbar-brand-box').css({'padding-left': '1.7rem', 'padding-right': '0.75rem'});
            $('.page-content').css('margin-top', mainNavHeight);
            $('.logo-lg > img').css({
                'display': 'inline',
                'height': '0px'
            });
            $('.logo-lg > img').animate({height: '30px'});
            $('.logo-sm > img').css({
                'display': 'inline',
                'height': '0px'
            });
            $('.logo-sm > img').animate({height: '22px'});
        }
        if (windowWidth >= 768) {
            const statusBarHeight = $('.status-bar').outerHeight() || 0;
            $('.collaborate-bar').css('top', pageScrollTop - mainNavHeight + 47 + statusBarHeight);
        } else {
            $('.collaborate-bar').css('top', 'unset');
        }
    } else {
        if (mainNavFixed) {
            mainNavFixed = false;
            $('.main-nav-container').removeClass('fixed');
            $('.navbar-brand-box').css({'padding-left': '0.75rem', 'padding-right': 0});
            $('.page-content').css('margin-top', 0);
            $('.logo-lg > img').animate({height: '0px'}, function () {
                $('.logo-lg > img').css({
                    'display': 'none',
                    'height': '0px'
                });
            });
            $('.logo-sm > img').animate({height: '0px'}, function () {
                $('.logo-sm > img').css({
                    'display': 'none',
                    'height': '0px'
                });
            });
            if (windowWidth >= 768) {
                $('.collaborate-bar').css('top', 0);
            } else {
                $('.collaborate-bar').css('top', 'unset');
            }
        }
    }

    if (fullScreenPanel && (pageScrollTop > 30)) {
        let width = 0;
        let height = 0;
        if (!$('.artwork-editor-header').hasClass('fixed')) {
            width = Math.ceil($('.artwork-editor-header').outerWidth());
            height = Math.ceil($('.artwork-editor-header').outerHeight());
            $('.artwork-editor-body').css('padding-top', height);
            $('.artwork-editor-header').css('width', width);
            $('.artwork-editor-header').addClass('shadow-sm');
            $('.artwork-editor-header').addClass('fixed');
            
        }
        height = Math.ceil($('#artwork-editor .asset-preview.page .thumbnail').height());
        if (
            ($('.template-fields').outerHeight() > height)
            && $('#artwork-editor .asset-preview.page').hasClass('template-preview-sm')
        ) {
            const editorHeaderHeight = $('.artwork-editor-header').outerHeight();
            const fitScreenHeight = $(window).height() - editorHeaderHeight - $('.asset-selection').height() - 110;
            let outOfRangeHeight = $('.template-preview-sm').outerHeight() - fitScreenHeight;
            if (outOfRangeHeight < 0) {
                outOfRangeHeight = 0;
            }
            if (pageScrollTop > (30 + outOfRangeHeight)) {
                if (!$('.template-preview-sm').hasClass('fixed')) {
                    width = Math.ceil($('.template-preview-sm').width());
                    $('.template-preview-sm').css({
                        'width': width,
                        'top': (70 + editorHeaderHeight - outOfRangeHeight)
                    });
                    $('.template-preview-sm').addClass('fixed');
                }
            } else {
                $('#artwork-editor .asset-preview.page').removeClass('fixed');
                $('#artwork-editor .asset-preview.page').css({
                    'top': 0,
                    'width': 'auto'
                });
            }
        } else {
            $('#artwork-editor .asset-preview.page').removeClass('fixed');
            $('#artwork-editor .asset-preview.page').css({
                'top': 0,
                'width': 'auto'
            });
        }
    } else {
        $('.artwork-editor-body').css('padding-top', 0);
        $('.artwork-editor-header').css('width', 'auto');
        $('.artwork-editor-header').removeClass('shadow-sm');
        $('.artwork-editor-header').removeClass('fixed');
        $('.template-preview-xs').css('top', 0);
        $('.template-preview-sm').css('top', 0);
        $('#artwork-editor .asset-preview.page').removeClass('fixed');
        $('#artwork-editor .asset-preview.page').css({
            'top': 0,
            'width': 'auto'
        });
    }
}

setInterval(function () {
    const addingAsset = $('body').hasClass('asset-add');
    const addingTemplate = $('body').hasClass('template-add');
    const editingArtwork = $('body').hasClass('artwork-edit');

    if (fullScreenPanel != (addingAsset || addingTemplate || editingArtwork)) {
        fullScreenPanel = (addingAsset || addingTemplate || editingArtwork);

        if (fullScreenPanel && mainNavFixed) {
            $('.page-content').css('margin-top', $('.main-nav-container').outerHeight());

            return null;
        }
        setMainNav();
    }
}, 50);
